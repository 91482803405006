import React, { useEffect, useState } from 'react';
import { Box, useColorModeValue, Text } from '@chakra-ui/react';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const API_BASE_URL = 'https://etc.querubimcompany.com';

const TreatmentVsIntercurrenceChart = ({ userId }) => {
  const [treatmentCount, setTreatmentCount] = useState(0);
  const [intercurrenceCount, setIntercurrenceCount] = useState(0);

  const boxBg = useColorModeValue('gray.50', 'gray.900');
  const textColor = useColorModeValue('gray.700', 'gray.200');

  useEffect(() => {
    // Fetch number of treatments for the user
    const fetchTreatments = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/dashboard/treatments`);
        setTreatmentCount(response.data.length);
      } catch (error) {
        console.error('Erro ao buscar tratamentos:', error);
      }
    };

    // Fetch number of intercurrences for the user
    const fetchIntercurrences = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/dashboard/intercurrences/${userId}`);
        setIntercurrenceCount(response.data.length);
      } catch (error) {
        console.error('Erro ao buscar intercorrências:', error);
      }
    };

    fetchTreatments();
    fetchIntercurrences();
  }, [userId]);

  // Configuração dos dados para o gráfico
  const data = {
    labels: ['Tratamentos', 'Intercorrências'],
    datasets: [
      {
        label: 'Quantidade',
        data: [treatmentCount, intercurrenceCount],
        backgroundColor: [
          'rgba(54, 162, 235, 0.6)', // Azul para tratamentos
          'rgba(255, 99, 132, 0.6)', // Vermelho para intercorrências
        ],
        borderColor: [
          'rgba(54, 162, 235, 1)',
          'rgba(255, 99, 132, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  // Configuração das opções para o gráfico
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          font: {
            size: 12,
          },
        },
      },
      title: {
        display: true,
        text: 'Comparação de Tratamentos vs Intercorrências',
        font: {
          size: 16,
        },
      },
    },
    scales: {
      x: {
        ticks: {
          font: {
            size: 12,
          },
        },
      },
      y: {
        ticks: {
          font: {
            size: 12,
          },
          stepSize: 1,
          beginAtZero: true,
        },
      },
    },
  };

  return (
    <Box
      p="24px" // Mantém a consistência de padding com outros componentes
      bg={boxBg}
      borderRadius="16px"
      shadow="xl"
      width="100%"
      maxW="full"
      mx="auto"
      borderWidth="1px"
      borderColor={useColorModeValue("gray.200", "gray.700")}
      mb="20px" // Mantém uma margem inferior para não encostar nos outros componentes
    >
      <Text
        textAlign="center"
        fontSize={{ base: 'lg', md: 'xl' }}
        fontWeight="bold"
        color={textColor}
        mb="4"
      >
        
      </Text>
      <Box height="400px" width="100%"> {/* Ajuste da altura e largura para ser consistente com outros componentes */}
        <Bar data={data} options={options} />
      </Box>
    </Box>
  );
};

export default TreatmentVsIntercurrenceChart;
