// src/App.js

import './assets/css/App.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import AuthLayout from './layouts/auth';
import AdminLayout from './layouts/admin';
import RTLLayout from './layouts/rtl';
import { ChakraProvider } from '@chakra-ui/react';
import initialTheme from './theme/theme';
import { useState } from 'react';
import PrivateRoute from './PrivateRoute'; // Importa o PrivateRoute

export default function Main() {
  const [currentTheme, setCurrentTheme] = useState(initialTheme);
  return (
    <ChakraProvider theme={currentTheme}>
      <Routes>
        <Route path="auth/*" element={<AuthLayout />} />
        <Route
          path="admin/*"
          element={
            <PrivateRoute>
              <AdminLayout theme={currentTheme} setTheme={setCurrentTheme} />
            </PrivateRoute>
          }
        />
        <Route
          path="rtl/*"
          element={
            <PrivateRoute>
              <RTLLayout theme={currentTheme} setTheme={setCurrentTheme} />
            </PrivateRoute>
          }
        />
        <Route path="/" element={<Navigate to="/admin" replace />} />
        {/* Rota para lidar com páginas não encontradas */}
        <Route path="*" element={<Navigate to="/auth/login" replace />} />
      </Routes>
    </ChakraProvider>
  );
}
