// src/views/admin/default/components/RecentConversations.js

import React from 'react';
import {
  Box,
  Text,
  Flex,
  Spinner,
  InputGroup,
  InputLeftElement,
  Input,
  VStack,
  Avatar,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';

const RecentConversations = ({
  isMobile,
  activeChatId,
  chats,
  loadingChats,
  filteredChats,
  searchTerm,
  setSearchTerm,
  handleChatClick,
  unreadChats,
  textColor,
  scrollbarWidth,
  scrollbarTrackBg,
  scrollbarThumbBg,
  scrollbarThumbHoverBg,
  scrollbarColor,
  inputBg,
  inputText,
  searchIconColor,
}) => {
  return (
    <Box
      width={{
        base: '100%',
        md: '350px',
      }}
      height={{
        base: '100%',
        md: '700px',
      }}
      bg="white"
      p="4"
      borderRadius="12px"
      shadow="lg"
      borderWidth="1px"
      borderColor="gray.200"
      overflowY="auto"
      sx={{
        '&::-webkit-scrollbar': {
          width: scrollbarWidth,
        },
        '&::-webkit-scrollbar-track': {
          background: scrollbarTrackBg,
          borderRadius: '12px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: scrollbarThumbBg,
          borderRadius: '12px',
          transition: 'background 0.3s',
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: scrollbarThumbHoverBg,
        },
        scrollbarWidth: 'thin',
        scrollbarColor: scrollbarColor,
      }}
    >
      <Text fontWeight="bold" mb="4" fontSize="xl" color={textColor}>
        Conversas Recentes
      </Text>
      {loadingChats ? (
        <Flex justify="center" align="center" height="100%">
          <Spinner color="blue.500" size="lg" />
        </Flex>
      ) : chats.length > 0 ? (
        <>
          <InputGroup mb="4">
            <InputLeftElement
              pointerEvents="none"
              children={<SearchIcon color={searchIconColor} w="15px" h="15px" />}
            />
            <Input
              variant="filled"
              fontSize="sm"
              bg={inputBg}
              color={inputText}
              placeholder="Pesquisar conversas..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </InputGroup>
          <VStack spacing="4" align="stretch">
            {filteredChats.length > 0 ? (
              filteredChats.map((chat) => (
                <Flex
                  key={chat.id}
                  p="3"
                  bg={String(chat.id) === String(activeChatId) ? 'gray.200' : 'white'}
                  borderRadius="md"
                  align="center"
                  onClick={() => handleChatClick(chat.id)}
                  cursor="pointer"
                  _hover={{ bg: 'gray.100' }}
                  position="relative" // Necessário para posicionar a bolinha
                >
                  <Avatar name={`Chat ${chat.id}`} size="md" mr="3" />
                  <Box flex="1" maxW="200px">
                    <Flex justify="space-between" align="center">
                      <Text fontWeight="bold" fontSize="sm" color={textColor}>
                        {chat.chatType}
                      </Text>
                      <Text fontSize="xs" color="gray.500">
                        {new Date(chat.lastActive).toLocaleTimeString([], {
                          hour: '2-digit',
                          minute: '2-digit',
                        })}
                      </Text>
                    </Flex>
                    <Flex justify="space-between" align="center">
                      <Text fontSize="xs" color="gray.500" isTruncated>
                        {chat.lastMessage || 'Sem mensagens'}
                      </Text>
                    </Flex>
                  </Box>
                  {/* Bolinha Laranja para Novas Mensagens */}
                  {unreadChats[String(chat.id)]?.count > 0 && (
                    <Box
                      position="absolute"
                      top="10px"
                      right="10px"
                      width="10px"
                      height="10px"
                      bg="orange.400"
                      borderRadius="full"
                    />
                  )}
                </Flex>
              ))
            ) : (
              <Text fontSize="sm" color="gray.500">
                Nenhuma conversa encontrada.
              </Text>
            )}
          </VStack>
        </>
      ) : (
        <Flex justify="center" align="center" height="100%">
          <Text fontSize="sm" color="gray.500">
            Você ainda não tem conversas.
          </Text>
        </Flex>
      )}
    </Box>
  );
};

export default RecentConversations;
