// src/views/admin/default/index.jsx

import React, { useEffect, useState } from 'react';
import { Box } from "@chakra-ui/react";
import { Navigate } from 'react-router-dom';
import AssistanceOptions from './components/AssistanceOptions'; // Importação do componente AssistanceOptions
import TreatmentAndIntercurrenceRequests from './components/TreatmentAndIntercurrenceRequests'; // Importação do componente TreatmentAndIntercurrenceRequests
import TreatmentVsIntercurrenceChart from './components/TreatmentVsIntercurrenceChart'; // Importação do novo componente de gráfico

export default function UserReports() {
  // Estados e hooks
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const userId = 1; // Definir o userId do usuário autenticado

  useEffect(() => {
    const checkAuthentication = () => {
      const token = localStorage.getItem('authToken');
      // Verifica se o token existe e define a autenticação
      if (token) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    };

    checkAuthentication();
  }, []);

  // Estado de carregamento ou verificação em progresso
  if (isAuthenticated === null) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <Navigate to="/auth/login" />;
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {/* Componente AssistanceOptions */}
      <Box mb="20px">
        <AssistanceOptions />
      </Box>

      {/* Componente TreatmentVsIntercurrenceChart */}
      <Box mb="20px">
        <TreatmentVsIntercurrenceChart userId={userId} />
      </Box>

      {/* Componente TreatmentAndIntercurrenceRequests */}
      <Box mb="20px">
        <TreatmentAndIntercurrenceRequests />
      </Box>
    </Box>
  );
}
