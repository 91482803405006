// src/AssistanceOptions.js

import React, { useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Text,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import AnamnesisForm from './AnamnesisForm';

const AssistanceOptions = () => {
  // Definindo cores e estilos
  const buttonBg = useColorModeValue("#F1F3F5", "#2C2C2E");
  const buttonHoverBg = useColorModeValue("#E5E7EB", "#3A3A3C");
  const buttonTextColor = useColorModeValue("#1C1C1E", "#FFFFFF");
  const fontColor = useColorModeValue("#2d3748", "white");

  const { isOpen, onOpen, onClose } = useDisclosure(); // Controla o modal
  const [helpType, setHelpType] = useState(null); // Tipo de ajuda selecionado

  const handleHelpClick = (type) => {
    setHelpType(type);
    onOpen();
  };

  return (
    <Box
      p="24px"
      bg={useColorModeValue("gray.50", "gray.900")}
      borderRadius="16px"
      shadow="xl"
      width="100%"
      maxW="full"
      mx="auto"
      overflow="hidden"
      borderWidth="1px"
      borderColor={useColorModeValue("gray.200", "gray.700")}
      transition="all 0.3s ease"
      _hover={{ shadow: "2xl" }}
      mt={{ base: '-4', md: '0' }}
      fontFamily="Arial, sans-serif"
    >
      <Text
        textAlign="center"
        fontSize={{ base: 'lg', md: 'xl' }}
        fontWeight="bold"
        color={fontColor}
        mb="6"
        fontFamily="Arial, sans-serif"
      >
        Como podemos ajudar?
      </Text>

      <Flex direction={{ base: 'column', md: 'row' }} gap="20px" justify="center">
        <Button
          onClick={() => handleHelpClick('intercorrencia')} // Define o tipo de ajuda
          bg={buttonBg}
          color={buttonTextColor}
          size="lg"
          borderRadius="20px"
          shadow="none"
          _hover={{ bg: buttonHoverBg, shadow: 'lg' }}
          transition="all 0.3s ease"
          fontSize={{ base: 'md', md: 'lg' }}
          px="32px"
          py="24px"
          width={{ base: 'full', md: 'auto' }}
          height="auto"
          fontFamily="Arial, sans-serif"
        >
          🩺 Ajuda com Intercorrência
        </Button>

        <Button
          onClick={() => handleHelpClick('tratamento')} // Define o tipo de ajuda
          bg={buttonBg}
          color={buttonTextColor}
          size="lg"
          borderRadius="20px"
          shadow="none"
          _hover={{ bg: buttonHoverBg, shadow: 'lg' }}
          transition="all 0.3s ease"
          fontSize={{ base: 'md', md: 'lg' }}
          px="32px"
          py="24px"
          width={{ base: 'full', md: 'auto' }}
          height="auto"
          fontFamily="Arial, sans-serif"
        >
          🧖‍♂️ Ajuda com Tratamento
        </Button>
      </Flex>

      {/* Modal com o formulário de anamnese */}
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Responder Perguntas</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <AnamnesisForm helpType={helpType} onClose={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default AssistanceOptions;
