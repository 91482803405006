// src/PrivateRoute.jsx

import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import axios from './axiosInstance'; // Agora aponta para src/axiosInstance.js
import { Flex, Spinner, Text } from '@chakra-ui/react'; // Importando componentes do Chakra UI

const PrivateRoute = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const token = localStorage.getItem('authToken');
        if (!token) {
          setIsAuthenticated(false);
          setLoading(false);
          return;
        }

        // Faz a requisição POST para /auth/protected
        const response = await axios.post('/auth/protected');

        if (response.status >= 200 && response.status < 300) { // Aceita qualquer status 2xx
          setIsAuthenticated(true);
        } else {
          console.error(`Erro inesperado: status ${response.status}`);
          setIsAuthenticated(false);
        }
      } catch (error) {
        console.error('Erro na verificação de autenticação:', error);
        setIsAuthenticated(false);
      } finally {
        setLoading(false);
      }
    };

    checkAuthentication();
  }, []);

  if (loading) {
    return (
      <Flex
        height="100vh"
        alignItems="center"
        justifyContent="center"
        direction="column"
      >
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
        <Text mt={4} fontSize="lg" color="gray.600">
          Carregando...
        </Text>
      </Flex>
    );
  }

  return isAuthenticated ? children : <Navigate to="/auth/login" />;
};

export default PrivateRoute;
