import React, { useState, useEffect } from 'react';
import { Box, Flex, Text, Button, useColorModeValue } from '@chakra-ui/react';
import axios from 'axios';

const API_BASE_URL = 'https://etc.querubimcompany.com';

const TreatmentAndIntercurrenceRequests = () => {
  const [treatments, setTreatments] = useState([]);
  const [intercurrences, setIntercurrences] = useState([]);

  // Cores e estilos do componente
  const boxBg = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.700', 'gray.200');
  const headerBg = useColorModeValue('gray.100', 'gray.700');
  const rowBg = useColorModeValue('#F7FAFC', '#2D3748');
  const rowHoverBg = useColorModeValue('#EDF2F7', '#4A5568');
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  useEffect(() => {
    // Busca de tratamentos
    const fetchTreatments = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/dashboard/treatments`);
        setTreatments(response.data);
      } catch (error) {
        console.error('Erro ao buscar tratamentos:', error);
      }
    };

    // Busca de intercorrências
    const fetchIntercurrences = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/dashboard/intercurrences`);
        setIntercurrences(response.data);
      } catch (error) {
        console.error('Erro ao buscar intercorrências:', error);
      }
    };

    fetchTreatments();
    fetchIntercurrences();
  }, []);

  return (
    <Box
      p="24px"
      bg={boxBg}
      borderRadius="16px"
      shadow="lg"
      width="100%"
      maxW="full"
      mx="auto"
      borderWidth="1px"
      borderColor={borderColor}
      transition="all 0.3s ease"
      _hover={{ shadow: '2xl' }}
      mt={{ base: '4', md: '8' }}
    >
      <Text
        textAlign="center"
        fontSize={{ base: '2xl', md: '3xl' }}
        fontWeight="bold"
        color={textColor}
        mb="8"
      >
       Status e Solicitações de Tratamento e Intercorrência
      </Text>

      {/* Tratamentos */}
      <Box mb="8">
        <Text
          fontSize="xl"
          fontWeight="bold"
          color={textColor}
          mb="4"
          textAlign="left"
        >
          Tratamentos
        </Text>

        {treatments.length === 0 ? (
          <Text color="gray.500" textAlign="center">
            Nenhuma solicitação de tratamento encontrada.
          </Text>
        ) : (
          <Box borderWidth="1px" borderRadius="12px" overflow="hidden">
            <Flex bg={headerBg} p="4">
              <Text flex="1" fontWeight="bold" color={textColor}>Título</Text>
              <Text flex="3" fontWeight="bold" color={textColor}>Descrição</Text>
              <Text flex="1" fontWeight="bold" color={textColor}>Ações</Text>
            </Flex>
            {treatments.map((treatment) => (
              <Flex
                key={treatment.id}
                p="4"
                bg={rowBg}
                borderBottomWidth="1px"
                borderColor={borderColor}
                _hover={{ bg: rowHoverBg }}
              >
                <Text flex="1" fontWeight="medium" color={textColor}>
                  {treatment.title}
                </Text>
                <Text flex="3" color="gray.500">
                  {treatment.description}
                </Text>
                <Box flex="1">
                  <Button
                    size="sm"
                    colorScheme="blue"
                    onClick={() => console.log('Visualizar detalhes do tratamento', treatment.id)}
                  >
                    Ver Detalhes
                  </Button>
                </Box>
              </Flex>
            ))}
          </Box>
        )}
      </Box>

      {/* Intercorrências */}
      <Box>
        <Text
          fontSize="xl"
          fontWeight="bold"
          color={textColor}
          mb="4"
          textAlign="left"
        >
          Intercorrências
        </Text>

        {intercurrences.length === 0 ? (
          <Text color="gray.500" textAlign="center">
            Nenhuma solicitação de intercorrência encontrada.
          </Text>
        ) : (
          <Box borderWidth="1px" borderRadius="12px" overflow="hidden">
            <Flex bg={headerBg} p="4">
              <Text flex="1" fontWeight="bold" color={textColor}>Título</Text>
              <Text flex="3" fontWeight="bold" color={textColor}>Descrição</Text>
              <Text flex="1" fontWeight="bold" color={textColor}>Ações</Text>
            </Flex>
            {intercurrences.map((intercurrence) => (
              <Flex
                key={intercurrence.id}
                p="4"
                bg={rowBg}
                borderBottomWidth="1px"
                borderColor={borderColor}
                _hover={{ bg: rowHoverBg }}
              >
                <Text flex="1" fontWeight="medium" color={textColor}>
                  {intercurrence.title}
                </Text>
                <Text flex="3" color="gray.500">
                  {intercurrence.description}
                </Text>
                <Box flex="1">
                  <Button
                    size="sm"
                    colorScheme="blue"
                    onClick={() => console.log('Visualizar detalhes da intercorrência', intercurrence.id)}
                  >
                    Ver Detalhes
                  </Button>
                </Box>
              </Flex>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default TreatmentAndIntercurrenceRequests;
