import {
  Button,
  Flex,
  Image,
  Link,
  Text,
  useColorModeValue,
  Box,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import logoWhite from "../../../assets/img/layout/logoWhite.png"; // Caminho corrigido
import product1 from "../../../assets/img/products/product1.webp"; // Imagem do produto 1
import product2 from "../../../assets/img/products/product2.webp"; // Imagem do produto 2
import product3 from "../../../assets/img/products/product3.webp"; // Imagem do produto 3
import React from "react";

export default function SidebarDocs() {
  const bgColor = useColorModeValue(
    "linear-gradient(135deg, #f0f4ff 0%, #d0d9ff 100%)",
    "linear-gradient(135deg, #2a2f45 0%, #3c435a 100%)"
  );
  const borderColor = useColorModeValue("white", "#2d3748");

  const [currentIndex, setCurrentIndex] = useState(0);
  const [fadeEffect, setFadeEffect] = useState("fade-in");

  const products = [
    { src: product1, title: "Fio de PDO Liso Mono 27G" },
    { src: product2, title: "Nose 19G-38MM-40MM" },
    { src: product3, title: "Mono 30G-38MM-50MM" },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setFadeEffect("fade-out");
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % products.length);
        setFadeEffect("fade-in");
      }, 300); // Tempo para esmaecer antes de trocar a imagem
    }, 3000);
    return () => clearInterval(interval);
  }, [products.length]);

  return (
    <Flex
      justify="center"
      direction="column"
      align="center"
      bg={bgColor}
      borderRadius="20px"
      position="relative"
      p="20px"
      w="100%"
      maxW="380px"
      mb="20px"
      boxShadow="lg"
    >
      <Flex
        border="4px solid"
        borderColor={borderColor}
        bg="white"
        borderRadius="50%"
        w="80px"
        h="80px"
        align="center"
        justify="center"
        mx="auto"
        position="absolute"
        left="50%"
        top="-40px"
        transform="translate(-50%, 0%)"
      >
        <Image src={logoWhite} w="36px" h="36px" />
      </Flex>

      <Flex
        direction="column"
        align="center"
        justify="center"
        mt="50px"
        mb="15px"
        px="10px"
      >
        <Text
          fontSize="16px"
          color={useColorModeValue("#2d3748", "white")}
          fontWeight="bold"
          lineHeight="150%"
          textAlign="center"
          px="10px"
          mb="10px"
        >
          Conheça Nossos Produtos Exclusivos
        </Text>
      </Flex>

      <Flex
        direction="column"
        align="center"
        justify="center"
        w="100%"
        p="10px"
      >
        <Box
          key={products[currentIndex].title}
          textAlign="center"
          transition="all 0.5s ease-in-out"
          w="100%"
          className={fadeEffect}
        >
          <Flex justify="center">
            <Image
              src={products[currentIndex].src}
              alt={products[currentIndex].title}
              borderRadius="10px"
              mb="10px"
              maxH="200px"
              objectFit="cover"
            />
          </Flex>
          <Text color={useColorModeValue("#2d3748", "white")} fontWeight="bold" mb="10px">
            {products[currentIndex].title}
          </Text>
          <Flex justify="center">
            <Link
              href="https://api.whatsapp.com/send/?phone=5527988417973&text=Olá, gostaria de saber mais sobre os produtos&type=phone_number&app_absent=0"
              isExternal
            >
              <Button
                bg="blue.500"
                _hover={{ bg: "blue.600" }}
                _active={{ bg: "blue.700" }}
                color="white"
                fontWeight="regular"
                fontSize="sm"
                minW="200px"
                mt="10px"
              >
                Saiba Mais
              </Button>
            </Link>
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
}
