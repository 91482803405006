// src/views/admin/default/components/Chat.js

import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import {
  Box,
  Input,
  Button,
  Flex,
  Text,
  VStack,
  Icon,
  Avatar,
  useColorModeValue,
  useBreakpointValue,
  Spinner,
  IconButton,
} from '@chakra-ui/react';
import { MdSend } from 'react-icons/md';
import { ArrowBackIcon } from '@chakra-ui/icons';
import axios from '../../../../axiosInstance';
import { io } from 'socket.io-client';
import RecentConversations from './RecentConversations';
import Message from './Message';
import NotificationManager from './NotificationManager'; // Importa o componente de notificações

// Importação direta do arquivo de áudio
import notificationSoundFile from '../../../../assets/audios/notifymoderno.mp3';

const SOCKET_SERVER_URL = 'https://etc.assist4uapp.com'; // Remova `API_BASE_URL` conforme solicitado

const Chat = () => {
  // Estados do componente
  const [chats, setChats] = useState([]);
  const [activeChatId, setActiveChatId] = useState(null);
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [unreadChats, setUnreadChats] = useState({});
  const [userRole, setUserRole] = useState(undefined);
  const [userId, setUserId] = useState(null);
  const [isSending, setIsSending] = useState(false);
  const [loadingChats, setLoadingChats] = useState(true);
  const [isConnected, setIsConnected] = useState(false);
  const [notifications, setNotifications] = useState([]); // Estado para notificações

  // Hooks e refs
  const isMobile = useBreakpointValue({ base: true, md: false });
  const messagesContainerRef = useRef(null);
  const socketRef = useRef(null);
  const activeChatIdRef = useRef(activeChatId);
  const currentChatIdRef = useRef(null);

  const messagesRef = useRef(messages);
  const chatsRef = useRef(chats);

  // Refs para manter os valores atualizados de userRole e userId
  const userRoleRef = useRef(userRole);
  const userIdRef = useRef(userId);

  // Ref para controlar a inicialização de unreadChats
  const unreadChatsInitialized = useRef(false);

  // Atualizar refs de mensagens e conversas
  useEffect(() => {
    messagesRef.current = messages;
  }, [messages]);

  useEffect(() => {
    chatsRef.current = chats;
  }, [chats]);

  // Atualizar os Refs quando userRole e userId mudarem
  useEffect(() => {
    userRoleRef.current = userRole;
  }, [userRole]);

  useEffect(() => {
    userIdRef.current = userId;
  }, [userId]);

  // Definições de cores para temas claros e escuros
  const scrollbarWidth = '8px';
  const scrollbarTrackBg = useColorModeValue('gray.100', 'gray.700');
  const scrollbarThumbBg = useColorModeValue('gray.400', 'gray.600');
  const scrollbarThumbHoverBg = useColorModeValue('gray.500', 'gray.700');
  const scrollbarColor = `${scrollbarThumbBg} ${scrollbarTrackBg}`;

  const chatBg = useColorModeValue('white', 'gray.800');
  const headerBg = useColorModeValue('gray.100', 'gray.600');
  const textColor = useColorModeValue('gray.800', 'white');
  const messageBoxBg = useColorModeValue('white', 'gray.700');
  const searchIconColor = useColorModeValue('gray.700', 'white');
  const inputBg = useColorModeValue('gray.100', 'gray.700');
  const inputText = useColorModeValue('gray.700', 'gray.100');

  // Função para adicionar notificações
  const addNotification = useCallback(
    (notification) => {
      setNotifications((prev) => [...prev, notification]);
    },
    []
  );

  // Função para buscar as conversas do usuário
  const fetchConversations = useCallback(async () => {
    try {
      const response = await axios.get('/dashboard/user-chats', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
          'Content-Type': 'application/json',
        },
      });
      // Ordenar conversas por última atividade
      const sortedChats = response.data.sort(
        (a, b) => new Date(b.lastActive) - new Date(a.lastActive)
      );
      setChats(sortedChats);
    } catch (error) {
      console.error('Erro ao buscar conversas:', error);
      addNotification({
        id: 'fetchConversationsError',
        title: 'Erro ao buscar conversas',
        description: 'Tente novamente.',
        status: 'error',
        duration: 3000,
        position: 'top',
        browserNotification: false,
      });
    } finally {
      setLoadingChats(false);
    }
  }, [addNotification]);

  // Função para enviar notificações do navegador
  const sendBrowserNotification = useCallback((title, body) => {
    if (Notification.permission === 'granted') {
      new Notification(title, { body });
    }
  }, []);

  // Solicitar permissão para notificações do navegador ao montar o componente
  useEffect(() => {
    if (Notification.permission === 'default') {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          console.log('Permissão para notificações concedida.');
        }
      });
    }
  }, []);

  // Função para reproduzir o som de notificação
  const playNotificationSound = useCallback(() => {
    try {
      const notificationSound = new Audio(notificationSoundFile);
      notificationSound.play().catch((error) => {
        console.error('Erro ao reproduzir som de notificação:', error);
      });
    } catch (error) {
      console.error('Erro ao inicializar som de notificação:', error);
    }
  }, []);

  // Função para desbloquear o áudio após interação do usuário
  const handleUserInteraction = useCallback(() => {
    playNotificationSound();
    window.removeEventListener('click', handleUserInteraction);
  }, [playNotificationSound]);

  // Função auxiliar para obter o tipo de chat com base no chatId
  const getChatType = useCallback((chatId) => {
    const chat = chatsRef.current.find((c) => c.id.toString() === chatId);
    return chat ? chat.chatType : 'Uma conversa';
  }, []);

  // Função para entrar na sala de chat via Socket.io
  const joinChatRoom = useCallback(
    (chatId) => {
      if (!socketRef.current) {
        console.warn('WebSocket não inicializado');
        return;
      }

      const chatIdNumber = parseInt(chatId, 10);
      if (isNaN(chatIdNumber)) {
        console.error('Invalid chatId:', chatId);
        return;
      }

      // Se já estiver em uma sala diferente, saia dela
      if (currentChatIdRef.current && currentChatIdRef.current !== chatId) {
        console.log(`Saindo da sala chat_${currentChatIdRef.current}`);
        socketRef.current.emit(
          'leaveChat',
          { chatId: currentChatIdRef.current },
          (response) => {
            if (response.status === 'ok') {
              console.log(`Saiu com sucesso da sala: chat_${currentChatIdRef.current}`);
            } else {
              console.error(`Falha ao sair da sala: chat_${currentChatIdRef.current}`);
            }
          }
        );
      }

      console.log('Enviando joinChat para o servidor com chatId:', chatId);

      socketRef.current.emit('joinChat', { chatId }, (response) => {
        console.log('Resposta do servidor para joinChat:', response);
        if (response.status === 'ok') {
          console.log(`Entrou com sucesso na sala de chat: chat_${chatId}`);
          currentChatIdRef.current = chatId;

          // Adicionar notificação de sucesso
          addNotification({
            id: `joinChatSuccess-${chatId}`,
            title: 'Entrou no chat',
            description: `Você está agora na conversa "${getChatType(chatId)}".`,
            status: 'success',
            duration: 3000,
            position: 'top-right',
            browserNotification: false,
          });
        } else {
          console.error(`Falha ao entrar na sala de chat: chat_${chatId}`);
          addNotification({
            id: `joinChatError-${chatId}`,
            title: 'Erro ao entrar no chat',
            description: 'Tente novamente.',
            status: 'error',
            duration: 3000,
            position: 'top',
            browserNotification: false,
          });
        }
      });
    },
    [addNotification, getChatType]
  );

  // Função para inicializar o WebSocket
  const initializeWebSocket = useCallback(() => {
    if (socketRef.current) return; // Evita inicialização múltipla

    console.log('Inicializando WebSocket...');
    socketRef.current = io(SOCKET_SERVER_URL, {
      transports: ['websocket'],
      secure: true,
      auth: {
        token: localStorage.getItem('authToken'),
      },
      reconnectionAttempts: 10,
      reconnectionDelay: 2000,
    });

    socketRef.current.on('connect', () => {
      console.log('Conectado ao servidor Socket.io');
      setIsConnected(true);

      // Refazer o fetch das conversas ao reconectar
      fetchConversations();
    });

    socketRef.current.on('disconnect', () => {
      console.log('Desconectado do WebSocket');
      setIsConnected(false);
      currentChatIdRef.current = null;
    });

    socketRef.current.on('connect_error', (error) => {
      console.error('Erro ao conectar ao WebSocket:', error);
      setIsConnected(false);
      addNotification({
        id: 'connectError',
        title: 'Erro ao conectar ao WebSocket',
        description: 'Tentando reconectar...',
        status: 'error',
        duration: 3000,
        position: 'top',
        browserNotification: false,
      });
    });

    // Listener para o evento 'reconnect'
    socketRef.current.on('reconnect', () => {
      console.log('Reconectado ao WebSocket, atualizando conversas...');
      setIsConnected(true);
      fetchConversations();

      // Reentrar na sala do chat ativo
      if (activeChatIdRef.current) {
        joinChatRoom(activeChatIdRef.current);
      }
    });

    // Remover listeners antigos antes de adicionar novos
    socketRef.current.off('newMessage');
    socketRef.current.off('newChat');

    // Listener para o evento 'newMessage'
    socketRef.current.on('newMessage', (message) => {
      console.log('Nova mensagem recebida:', message);
      let incomingChatId = null;

      if (message.chatId) {
        incomingChatId = message.chatId.toString();
      } else if (message.chat && message.chat.id) {
        incomingChatId = message.chat.id.toString();
      } else {
        console.error('Mensagem recebida sem chatId:', message);
        return; // Ignorar mensagens sem chatId definido
      }

      console.log(`Mensagem recebida para chatId: ${incomingChatId}`);

      const messageExists = messagesRef.current.some((msg) => msg.id === message.id);
      if (messageExists) {
        console.log('Mensagem duplicada detectada. Ignorando...');
        return;
      }

      // Determinar se a mensagem é própria com base no sender e userRole
      let isOwnMessage = false;

      const currentUserRole = userRoleRef.current;

      console.log(
        `Current User Role: ${currentUserRole}, Message Sender: ${message.sender}`
      );

      if (
        currentUserRole &&
        message.sender &&
        currentUserRole.toLowerCase() === message.sender.toLowerCase()
      ) {
        isOwnMessage = true;
      }

      console.log(`Is Own Message: ${isOwnMessage}`);

      // Adicionar a mensagem ao estado messages se for do chat ativo
      if (incomingChatId === activeChatIdRef.current) {
        setMessages((prevMessages) => [...prevMessages, message]);
      } else {
        // Atualizar unreadChats para o chat não ativo
        setUnreadChats((prev) => ({
          ...prev,
          [incomingChatId]: {
            count: (prev[incomingChatId]?.count || 0) + 1,
            lastMessageTimestamp: message.timestamp,
          },
        }));
      }

      // Atualizar a lista de conversas com a última mensagem
      setChats((prevChats) => {
        const updatedChats = prevChats.map((chat) => {
          const chatIdStr = chat.id.toString();
          if (chatIdStr === incomingChatId) {
            return {
              ...chat,
              lastMessage: message.message,
              lastActive: message.timestamp,
            };
          } else {
            return chat;
          }
        });

        // Se o chat não estiver na lista, adicione-o
        const chatExists = updatedChats.some((chat) => chat.id.toString() === incomingChatId);
        if (!chatExists) {
          const newChat = {
            id: incomingChatId,
            userId: message.userId,
            specialistId: message.specialistId,
            chatType: 'Nova Conversa', // Ajuste conforme necessário
            createdAt: new Date().toISOString(),
            lastMessage: message.message,
            lastActive: message.timestamp,
          };
          updatedChats.push(newChat);
        }

        // Ordenar as conversas por última atividade
        updatedChats.sort((a, b) => new Date(b.lastActive) - new Date(a.lastActive));

        console.log('Chats atualizados:', updatedChats);
        return updatedChats;
      });

      // Se a mensagem não for própria e não estiver no chat ativo, adicionar notificação
      if (!isOwnMessage && incomingChatId !== activeChatIdRef.current) {
        console.log('Disparando notificações para nova mensagem em chat não ativo');

        // Adicionar notificação
        addNotification({
          id: `newMessageToast-${incomingChatId}-${Date.now()}`, // Garantir ID único
          title: 'Nova Mensagem',
          description: `Você recebeu uma nova mensagem em "${getChatType(incomingChatId)}".`,
          status: 'info',
          duration: 5000,
          position: 'top-right',
          browserNotification: !document.hasFocus(),
        });

        // Reproduzir som de notificação
        playNotificationSound();

        // Enviar notificação do navegador se a aba não estiver em foco
        if (!document.hasFocus()) {
          sendBrowserNotification(
            'Nova Mensagem',
            `Você recebeu uma nova mensagem em "${getChatType(incomingChatId)}".`
          );
        }
      } else {
        console.log('Mensagem enviada pelo próprio usuário ou no chat ativo.');
        // Não precisamos fazer nada adicional aqui
      }
    });

    // Listener para o evento 'newChat'
    socketRef.current.on('newChat', (newChat) => {
      console.log('Novo chat recebido via WebSocket:', newChat);
      setChats((prevChats) => {
        const updatedChats = [newChat, ...prevChats];
        // Ordenar as conversas por última atividade
        updatedChats.sort((a, b) => new Date(b.lastActive) - new Date(a.lastActive));
        return updatedChats;
      });

      // Emitir notificação apenas para novos chats que não são o chat ativo
      if (newChat.id !== activeChatIdRef.current) {
        // Adicionar notificação
        addNotification({
          id: `newChatToast-${newChat.id}-${Date.now()}`, // Garantir ID único
          title: 'Novo Chat',
          description: `Você recebeu uma nova conversa: ${newChat.chatType}`,
          status: 'success',
          duration: 5000,
          position: 'top-right',
          browserNotification: !document.hasFocus(),
        });

        // Reproduzir som de notificação
        playNotificationSound();

        // Enviar notificação do navegador se a aba não estiver em foco
        if (!document.hasFocus()) {
          sendBrowserNotification(
            'Novo Chat',
            `Você recebeu uma nova conversa: ${newChat.chatType}.`
          );
        }

        // Atualizar unreadChats para o novo chat
        setUnreadChats((prev) => ({
          ...prev,
          [newChat.id.toString()]: {
            count: 1, // Iniciar com 1 mensagem não lida
            lastMessageTimestamp: newChat.lastActive,
          },
        }));
      }
    });

    // Listener para desbloquear áudio após interação do usuário
    window.addEventListener('click', handleUserInteraction);

    // Cleanup function para remover listeners quando o componente desmontar
    return () => {
      if (socketRef.current) {
        socketRef.current.off('newMessage');
        socketRef.current.off('newChat');
        socketRef.current.disconnect();
      }
      window.removeEventListener('click', handleUserInteraction);
    };
  }, [
    addNotification,
    playNotificationSound,
    fetchConversations,
    joinChatRoom,
    sendBrowserNotification,
    getChatType,
    handleUserInteraction,
  ]);

  // Função para obter a função do usuário e userId
  const fetchUserRole = useCallback(async () => {
    try {
      const response = await axios.get('/dashboard/user-role', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
          'Content-Type': 'application/json',
        },
      });
      const role = response.data.role.toUpperCase();
      const fetchedUserId = response.data.userId; // Supondo que o backend retorne userId
      setUserRole(role);
      setUserId(fetchedUserId);
      userRoleRef.current = role; // Atualiza o ref imediatamente
      userIdRef.current = fetchedUserId;
      console.log('User Role:', role);
      console.log('User ID:', fetchedUserId);

      // Inicializa o WebSocket após obter o userRole
      initializeWebSocket();
    } catch (error) {
      console.error('Erro ao buscar a função do usuário:', error);
      addNotification({
        id: 'fetchUserRoleError',
        title: 'Erro ao buscar informações do usuário',
        description: 'Tente novamente.',
        status: 'error',
        duration: 3000,
        position: 'top',
        browserNotification: false,
      });
      setUserRole('UNKNOWN');
      setUserId(null);
    }
  }, [initializeWebSocket, addNotification]);

  useEffect(() => {
    fetchUserRole();
  }, [fetchUserRole]);

  useEffect(() => {
    fetchConversations();
  }, [fetchConversations]);

  // Inicializar o estado unreadChats apenas uma vez após o carregamento inicial das conversas
  useEffect(() => {
    if (!loadingChats && chats.length > 0 && !unreadChatsInitialized.current) {
      const initialUnreadChats = {};
      chats.forEach((chat) => {
        initialUnreadChats[String(chat.id)] = {
          count: 0,
          lastMessageTimestamp: chat.lastActive,
        };
      });
      setUnreadChats(initialUnreadChats);
      unreadChatsInitialized.current = true;
    }
  }, [loadingChats, chats]);

  // Adicionar listener para o evento 'focus' da janela
  useEffect(() => {
    const handleWindowFocus = () => {
      console.log('Janela ganhou foco, atualizando conversas...');
      fetchConversations();
    };

    window.addEventListener('focus', handleWindowFocus);

    return () => {
      window.removeEventListener('focus', handleWindowFocus);
    };
  }, [fetchConversations]);

  // Função para buscar mensagens de um chat específico
  const fetchMessages = useCallback(
    async (chatId) => {
      if (!chatId) {
        console.warn('ID do chat inválido:', chatId);
        return;
      }

      try {
        setMessages([]);
        const response = await axios.get(`/dashboard/chat/${chatId}/messages`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`,
            'Content-Type': 'application/json',
          },
        });
        setMessages(response.data);
      } catch (error) {
        console.error('Erro ao buscar mensagens:', error);
        addNotification({
          id: `fetchMessagesError-${chatId}-${Date.now()}`,
          title: 'Erro ao buscar mensagens',
          description: 'Tente novamente.',
          status: 'error',
          duration: 3000,
          position: 'top',
          browserNotification: false,
        });
      }
    },
    [addNotification]
  );

  // Hook para buscar mensagens e entrar na sala quando um chat é ativado
  useEffect(() => {
    if (activeChatId && !isNaN(parseInt(activeChatId, 10))) {
      fetchMessages(activeChatId);
      joinChatRoom(activeChatId);
    }
  }, [activeChatId, fetchMessages, joinChatRoom]);

  // Atualizar a referência do chat ativo
  useEffect(() => {
    activeChatIdRef.current = activeChatId;
  }, [activeChatId]);

  // Scroll automático para a última mensagem
  useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTo({
        top: messagesContainerRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [messages]);

  // Lógica de pesquisa usando useMemo
  const filteredChats = useMemo(() => {
    if (searchTerm.trim() === '') {
      return chats;
    } else {
      return chats.filter(
        (chat) =>
          chat.chatType.toLowerCase().includes(searchTerm.toLowerCase()) ||
          chat.lastMessage?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
  }, [searchTerm, chats]);

  // Função para enviar uma mensagem (via Axios apenas)
  const handleSendMessage = useCallback(async () => {
    if (!socketRef.current || !isConnected) {
      console.error('Tentativa de envio de mensagem sem conexão WebSocket');
      addNotification({
        id: 'sendMessageError',
        title: 'WebSocket não conectado',
        description: 'Tentando reconectar...',
        status: 'error',
        duration: 3000,
        position: 'top',
        browserNotification: false,
      });
      return;
    }

    if (message.trim() === '' || !activeChatId) return;

    const chatIdNumber = parseInt(activeChatId, 10);
    if (isNaN(chatIdNumber)) {
      console.error('chatId is not a valid number:', activeChatId);
      return;
    }

    const newMessage = {
      chatId: chatIdNumber,
      message: message.trim(),
      mediaUrl: null,
      // Removido: sender, pois o backend já define o sender com base no token
    };

    try {
      setIsSending(true);
      console.log('Enviando mensagem para chatId:', newMessage.chatId);
      const response = await axios.post('/dashboard/chat/message', newMessage, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200 || response.status === 201) {
        console.log('Mensagem enviada com sucesso via Axios:', response.data);

        // **Não adicionamos a mensagem manualmente ao estado `messages` aqui**
        // **Confiamos no evento 'newMessage' via WebSocket para atualizar as mensagens**

        // Atualizar a lista de conversas com a última mensagem enviada
        const timestamp = new Date().toISOString();
        setChats((prevChats) =>
          prevChats
            .map((chat) =>
              String(chat.id) === String(activeChatId)
                ? { ...chat, lastMessage: message.trim(), lastActive: timestamp }
                : chat
            )
            .sort((a, b) => new Date(b.lastActive) - new Date(a.lastActive))
        );

        // Limpar o campo de mensagem
        setMessage('');
      } else {
        throw new Error('Falha no envio da mensagem');
      }
    } catch (error) {
      console.error('Erro ao enviar mensagem:', error);
      addNotification({
        id: `sendMessageError-${Date.now()}`,
        title: 'Erro ao enviar mensagem',
        description: 'Tente novamente.',
        status: 'error',
        duration: 3000,
        position: 'top',
        browserNotification: false,
      });
    } finally {
      setIsSending(false);
    }
  }, [isConnected, message, activeChatId, addNotification]);

  // Função para detectar o pressionamento da tecla Enter
  const handleTyping = useCallback(
    (e) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        // Enviar apenas ao pressionar Enter sem Shift
        e.preventDefault();
        handleSendMessage();
      }
    },
    [handleSendMessage]
  );

  // Função para selecionar um chat
  const handleChatClick = useCallback(
    (chatId) => {
      console.log(`Selecionando chat com ID: ${chatId}`);
      setActiveChatId(chatId.toString());

      // Resetar contagem de mensagens não lidas para este chat
      setUnreadChats((prev) => ({
        ...prev,
        [chatId.toString()]: {
          count: 0,
          lastMessageTimestamp:
            prev[chatId.toString()]?.lastMessageTimestamp || null,
        },
      }));
    },
    []
  );

  // Condicional de renderização do spinner
  if (userRole === undefined) {
    return (
      <Flex justify="center" align="center" height="100vh">
        <Spinner size="xl" />
      </Flex>
    );
  }

  return (
    <>
      {/* Gerenciador de Notificações */}
      <NotificationManager notifications={notifications} playSound={true} />

      <Flex
        height="calc(85vh - 80px)"
        p={{ base: '4px', md: '16px' }}
        gap={{ base: '4px', md: '16px' }}
        mt="80px"
        direction={{ base: 'column', md: 'row' }}
        overflow="hidden"
        align={{ base: 'stretch', md: 'stretch' }}
      >
        {/* Lista de Conversas */}
        {(!isMobile || !activeChatId) && (
          <RecentConversations
            isMobile={isMobile}
            activeChatId={activeChatId}
            chats={chats}
            loadingChats={loadingChats}
            filteredChats={filteredChats}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            handleChatClick={handleChatClick}
            unreadChats={unreadChats}
            textColor={textColor}
            scrollbarWidth={scrollbarWidth}
            scrollbarTrackBg={scrollbarTrackBg}
            scrollbarThumbBg={scrollbarThumbBg}
            scrollbarThumbHoverBg={scrollbarThumbHoverBg}
            scrollbarColor={scrollbarColor}
            inputBg={inputBg}
            inputText={inputText}
            searchIconColor={searchIconColor}
          />
        )}

        {/* Chat Ativo */}
        <Flex
          flex="1"
          direction="column"
          bg={chatBg}
          borderRadius="12px"
          shadow="lg"
          width={{
            base: '100%',
            md: 'calc(100% - 350px)',
          }}
          display={!activeChatId && isMobile ? 'none' : 'flex'}
          overflow="hidden"
        >
          {activeChatId ? (
            <>
              {/* Cabeçalho do Chat */}
              <Box
                width="100%"
                bg={headerBg}
                p="16px"
                borderTopRadius="12px"
                display="flex"
                alignItems="center"
              >
                {isMobile && (
                  <IconButton
                    aria-label="Voltar para conversas"
                    icon={<ArrowBackIcon />}
                    mr="3"
                    onClick={() => setActiveChatId(null)}
                    variant="ghost"
                    size="md"
                  />
                )}
                <Avatar name={`Chat ${activeChatId}`} size="md" mr="3" />
                <Text fontSize="lg" fontWeight="bold" color={textColor}>
                  Chat {activeChatId}
                </Text>
              </Box>

              {/* Área de Mensagens */}
              <Box
                flex="1"
                overflowY="auto"
                p="4"
                bg={messageBoxBg}
                ref={messagesContainerRef}
                sx={{
                  '&::-webkit-scrollbar': {
                    width: scrollbarWidth,
                  },
                  '&::-webkit-scrollbar-track': {
                    background: scrollbarTrackBg,
                    borderRadius: '12px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    background: scrollbarThumbBg,
                    borderRadius: '12px',
                    transition: 'background 0.3s',
                  },
                  '&::-webkit-scrollbar-thumb:hover': {
                    background: scrollbarThumbHoverBg,
                  },
                  scrollbarWidth: 'thin',
                  scrollbarColor: scrollbarColor,
                }}
              >
                <VStack spacing="4" align="stretch">
                  {messages.length > 0 ? (
                    messages.map((msg) => {
                      // Verificar se a mensagem é do usuário atual baseado no 'sender' e 'userRole'
                      let isOwnMessage = false;

                      // Se o usuário atual é um "professional"
                      if (userRole === 'PROFESSIONAL') {
                        isOwnMessage = msg.sender === 'professional';
                      }
                      // Se o usuário atual é um "specialist"
                      else if (userRole === 'SPECIALIST') {
                        isOwnMessage = msg.sender === 'specialist';
                      }

                      return (
                        <Message
                          key={msg.id || msg.timestamp}
                          message={msg}
                          isOwnMessage={isOwnMessage}
                        />
                      );
                    })
                  ) : (
                    <Flex justify="center" align="center" height="100%">
                      <Text fontSize="sm" color="gray.500">
                        Sem mensagens neste chat.
                      </Text>
                    </Flex>
                  )}
                </VStack>
              </Box>

              {/* Área de Envio de Mensagens */}
              <Flex align="center" p="4" bg={messageBoxBg} borderBottomRadius="12px">
                <Input
                  placeholder="Digite sua mensagem..."
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  onKeyDown={handleTyping}
                  mr="2"
                  borderRadius="24px"
                  bg="gray.200"
                  disabled={isSending}
                />
                <Button
                  onClick={handleSendMessage}
                  bg="blue.500" // Balão azul para mensagens enviadas
                  color="white"
                  borderRadius="full"
                  _hover={{ bg: 'blue.600' }} // Hover com tonalidade mais escura
                  isLoading={isSending}
                >
                  <Icon as={MdSend} />
                </Button>
              </Flex>
            </>
          ) : (
            <Flex justify="center" align="center" flex="1">
              <Text fontSize="md" color="gray.500">
                Selecione uma conversa para começar a conversar.
              </Text>
            </Flex>
          )}
        </Flex>
      </Flex>
    </>
  );
};

export default Chat;
