// src/views/admin/default/components/NotificationManager.js

import { useEffect } from 'react';
import { useToast } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import notificationSoundFile from '../../../../assets/audios/notifymoderno.mp3'; // Ajuste o caminho conforme necessário

const NotificationManager = ({ notifications, playSound }) => {
  const toast = useToast();

  useEffect(() => {
    notifications.forEach((notification) => {
      const {
        id,
        title,
        description,
        status,
        duration,
        position,
        browserNotification,
      } = notification;

      // Exibir Toast do Chakra UI
      if (!toast.isActive(id)) {
        toast({
          id,
          title,
          description,
          status,
          duration,
          isClosable: true,
          position,
        });
      }

      // Enviar Notificação do Navegador, se necessário
      if (browserNotification && Notification.permission === 'granted') {
        new Notification(title, { body: description });
      }

      // Reproduzir som de notificação
      if (playSound) {
        const audio = new Audio(notificationSoundFile);
        audio.play().catch((error) =>
          console.error('Erro ao reproduzir som:', error)
        );
      }
    });
  }, [notifications, toast, playSound]);

  return null; // Este componente não renderiza nada
};

NotificationManager.propTypes = {
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string,
      status: PropTypes.oneOf(['info', 'success', 'warning', 'error']),
      duration: PropTypes.number,
      position: PropTypes.string,
      browserNotification: PropTypes.bool,
    })
  ).isRequired,
  playSound: PropTypes.bool,
};

NotificationManager.defaultProps = {
  playSound: false,
};

export default NotificationManager;
