import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  Spinner,
  Alert,
  AlertIcon,
  Flex,
  Text,
  Image,
  useColorModeValue,
  useToast,
  useBreakpointValue,
} from '@chakra-ui/react';
import axios from '../../../../axiosInstance';

const AnamnesisForm = ({ helpType, onClose }) => {
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState({});
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [filePreviews, setFilePreviews] = useState([]);
  const toast = useToast();

  const bgColor = useColorModeValue('gray.50', 'gray.700');
  const alertBg = useColorModeValue('gray.100', 'gray.600');
  const buttonBg = useColorModeValue('#3182CE', '#2B6CB0');
  const buttonHoverBg = useColorModeValue('#2B6CB0', '#2C5282');

  // Configuração responsiva para ajustar a largura e o espaçamento no mobile
  const formWidth = useBreakpointValue({ base: '100%', md: '600px' });
  const formPadding = useBreakpointValue({ base: 2, md: 4 });

  const loadQuestions = useCallback(async () => {
    if (!helpType) return;

    setLoading(true);
    setError(null);

    const questionEndpoints = {
      intercorrencia: '/dashboard/questions/intercurrence',
      tratamento: '/dashboard/questions/treatment',
    };

    try {
      const response = await axios.get(questionEndpoints[helpType]);

      if (response.data && Array.isArray(response.data)) {
        setQuestions(response.data);
      } else {
        setError('Nenhuma pergunta disponível ou formato inesperado.');
      }
    } catch (err) {
      if (err.response && err.response.status === 404) {
        setError('Recurso não encontrado (404).');
      } else {
        setError('Erro ao carregar perguntas. Tente novamente mais tarde.');
      }
    } finally {
      setLoading(false);
    }
  }, [helpType]);

  useEffect(() => {
    if (helpType) {
      loadQuestions();
    }
  }, [helpType, loadQuestions]);

  const handleChange = (e, questionText) => {
    const { value } = e.target;
    setAnswers((prev) => ({
      ...prev,
      [questionText]: value,
    }));
  };

  const handleFileChange = (e, questionText) => {
    const files = Array.from(e.target.files);
    const validFiles = [];
    const previews = [];

    files.forEach((file) => {
      const fileType = file.type;
      const fileSize = file.size / 1024 / 1024;

      if (!['image/jpeg', 'image/jpg', 'image/png', 'image/webp'].includes(fileType)) {
        setError('Apenas arquivos JPEG, JPG, PNG e WEBP são permitidos.');
        return;
      }
      if (fileSize > 5) {
        setError('O tamanho máximo permitido para cada arquivo é 5MB.');
        return;
      }

      validFiles.push(file);
      const reader = new FileReader();
      reader.onload = () => {
        previews.push(reader.result);
        setFilePreviews([...previews]);
      };
      reader.readAsDataURL(file);
    });

    setAnswers((prev) => ({
      ...prev,
      [questionText]: validFiles,
    }));
    setError(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    setError(null);
  
    const answersPayload = questions.map((question) => ({
      questionId: question.questionId,
      response: answers[question.questionText] ? String(answers[question.questionText]) : '',
    }));
  
    const formData = new FormData();
    
    // Garantindo que o chatType está em maiúsculas
    const formattedHelpType = helpType === 'tratamento' ? 'TREATMENT' : helpType === 'intercorrencia' ? 'INTERCURRENCE' : '';
    
    if (!formattedHelpType) {
      setError('Tipo de ajuda inválido. Tente novamente.');
      setSubmitting(false);
      return;
    }
    
    formData.append('chatType', formattedHelpType);
    formData.append('answers', JSON.stringify(answersPayload));
  
    // Adicionar arquivos ao formData
    if (answers['Envie Fotos ou Videos da situação:'] && answers['Envie Fotos ou Videos da situação:'].length > 0) {
      answers['Envie Fotos ou Videos da situação:'].forEach((file) => {
        formData.append('files', file);
      });
    }
  
    try {
      const response = await axios.post('https://etc.assist4uapp.com/dashboard/submit-answers', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      if (response.status === 200 || response.status === 201) {
        toast({
          title: 'Chat Iniciado',
          description: 'O chat foi iniciado com sucesso!',
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top',
        });
        onClose();
      } else {
        toast({
          title: 'Erro ao enviar respostas',
          description: 'Tente novamente.',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top',
        });
      }
    } catch (err) {
      toast({
        title: 'Erro na conexão',
        description: 'Houve um problema ao enviar as respostas. Verifique sua conexão.',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top',
      });
    } finally {
      setSubmitting(false);
    }
  };
  

  const getOptionsForQuestion = (questionText) => {
    switch (questionText) {
      case 'Sexo:':
        return ['Masculino', 'Feminino'];
      case 'Já fez tratamento estético?':
        return ['Sim', 'Não'];
      case 'Tipo de face (hiper volumétrica, normo volumétrica ou hipo volumétrica)?':
        return ['Hiper volumétrica', 'Normo volumétrica', 'Hipo volumétrica'];
      case 'Qual textura de pele (flácida ou firme)?':
        return ['Flácida', 'Firme'];
      default:
        return [];
    }
  };

  if (loading) {
    return (
      <Flex align="center" justify="center" direction="column" py={10}>
        <Spinner size="xl" />
        <Text mt={4} fontSize="lg" color="gray.600">
          Carregando perguntas...
        </Text>
      </Flex>
    );
  }

  if (error && questions.length === 0) {
    return (
      <Box>
        <Alert status="error" bg={alertBg} mb={4}>
          <AlertIcon />
          {error}
        </Alert>
        <Button onClick={onClose} colorScheme="blue" bg={buttonBg} _hover={{ bg: buttonHoverBg }}>
          Fechar
        </Button>
      </Box>
    );
  }

  return (
    <Box
      as="form"
      onSubmit={handleSubmit}
      maxW={formWidth}
      mx="auto"
      p={formPadding}
      bg={bgColor}
      borderRadius="md"
      boxShadow="md"
      maxHeight="80vh"
      overflowY="auto"
      sx={{
        '&::-webkit-scrollbar': { width: '8px' },
        '&::-webkit-scrollbar-track': { background: '#f1f1f1', borderRadius: '8px' },
        '&::-webkit-scrollbar-thumb': { background: '#888', borderRadius: '8px' },
        '&::-webkit-scrollbar-thumb:hover': { background: '#555' },
      }}
    >
      {questions.length > 0 ? (
        questions.map((question, index) => (
          <FormControl key={index} mb={4} isRequired>
            <FormLabel fontSize={{ base: 'sm', md: 'md' }}>{question.questionText}</FormLabel>
            {question.type === 'number' && (
              <Input
                type="number"
                placeholder="Digite sua idade"
                value={answers[question.questionText] || ''}
                onChange={(e) => handleChange(e, question.questionText)}
                bg={bgColor}
              />
            )}
            {question.type === 'select' && (
              <Select
                placeholder="Selecione uma opção"
                value={answers[question.questionText] || ''}
                onChange={(e) => handleChange(e, question.questionText)}
                bg={bgColor}
              >
                {getOptionsForQuestion(question.questionText).map((option, idx) => (
                  <option key={idx} value={option}>
                    {option}
                  </option>
                ))}
              </Select>
            )}
            {question.type === 'texto' && (
              <Textarea
                placeholder="Digite a área a ser tratada"
                value={answers[question.questionText] || ''}
                onChange={(e) => handleChange(e, question.questionText)}
                bg={bgColor}
              />
            )}
            {question.type === 'sim_nao' && (
              <Select
                placeholder="Selecione uma opção"
                value={answers[question.questionText] || ''}
                onChange={(e) => handleChange(e, question.questionText)}
                bg={bgColor}
              >
                {getOptionsForQuestion(question.questionText).map((option, idx) => (
                  <option key={idx} value={option}>
                    {option}
                  </option>
                ))}
              </Select>
            )}
            {question.type === 'arquivo' && (
              <>
                <Button as="label" htmlFor={`file-input-${index}`} colorScheme="blue" mt={2}>
                  Escolher Fotos
                </Button>
                <Input
                  id={`file-input-${index}`}
                  type="file"
                  accept=".jpg,.jpeg,.png,.webp"
                  multiple
                  onChange={(e) => handleFileChange(e, question.questionText)}
                  display="none"
                />
                {filePreviews.length > 0 && (
                  <Flex mt={2} wrap="wrap" gap={2}>
                    {filePreviews.map((preview, idx) => (
                      <Image
                        key={idx}
                        src={preview}
                        alt={`Preview ${idx}`}
                        boxSize={{ base: '60px', md: '80px' }}
                        objectFit="cover"
                        borderRadius="md"
                      />
                    ))}
                  </Flex>
                )}
              </>
            )}
          </FormControl>
        ))
      ) : (
        <Alert status="info" bg={alertBg} mb={4}>
          <AlertIcon />
          Nenhuma pergunta disponível.
        </Alert>
      )}

      <Button
        type="submit"
        colorScheme="blue"
        isLoading={submitting}
        loadingText="Enviando"
        width="full"
        bg={buttonBg}
        _hover={{ bg: buttonHoverBg }}
        mt={4}
      >
        Enviar Respostas
      </Button>
    </Box>
  );
};

export default AnamnesisForm;
