// src/components/Message.js

import React from 'react';
import { Box, Flex, Text, useColorModeValue } from '@chakra-ui/react';

const Message = ({ message, isOwnMessage }) => {
  // Cores de fundo aprimoradas
  const userMessageBg = useColorModeValue('linear(to-r, blue.500, blue.400)', 'linear(to-r, blue.300, blue.200)');
  const receivedMessageBg = useColorModeValue('gray.200', 'gray.700');
  
  // Cores do texto
  const messageTextColor = isOwnMessage ? 'white' : 'black';
  const timestampColor = isOwnMessage ? 'whiteAlpha.800' : 'gray.500'; // Cor da hora mais suave para o usuário atual

  // Alinhamento e estilos do balão de mensagem
  const messageAlign = isOwnMessage ? 'flex-end' : 'flex-start';
  const messageBg = isOwnMessage ? userMessageBg : receivedMessageBg;
  const boxShadow = isOwnMessage ? '0px 2px 6px rgba(0, 0, 0, 0.2)' : '0px 1px 4px rgba(0, 0, 0, 0.1)';

  return (
    <Flex justify={messageAlign} align="flex-end" mb="2">
      <Box
        p="12px"
        bgGradient={isOwnMessage ? messageBg : undefined} // Aplica o gradiente apenas para o usuário atual
        bg={isOwnMessage ? undefined : messageBg} // Aplica a cor de fundo para mensagens recebidas
        color={messageTextColor}
        borderRadius={isOwnMessage ? '15px 15px 0 15px' : '15px 15px 15px 0'} // Bordas arredondadas diferenciadas
        maxWidth="70%"
        wordBreak="break-word"
        boxShadow={boxShadow}
      >
        <Text fontSize="md" mb="1">{message.message}</Text>
        <Text
          fontSize="xs"
          color={timestampColor}
          textAlign={isOwnMessage ? 'right' : 'left'}
        >
          {new Date(message.timestamp).toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
          })}
        </Text>
      </Box>
    </Flex>
  );
};

export default Message;
